import React from "react";
import { graphql } from "gatsby";
import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";

import PageHeader from "../components/product-page-header";
import PostMain from "../components/post-main";
import ProductMain from "../components/product-main";

export const query = graphql`
  fragment Si on SanityImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query ProductTemplateQuery($id: String!) {
    productPage: sanityProductPage(id: { eq: $id }) {
      id
      title
      flexibleContent {
        ... on SanityFcPartners {
          _key
          _type
          title
          partners {
            _key
            partner {
              name
              slug {
                current
              }
              link
              logo {
                asset {
                  extension
                  url
                  _id
                }
                alt
              }
            }
          }
        }
        ... on SanityProductInAction {
          _type
          title
          subtitle
          _rawDescription
          showcase {
            _key
            showcase {
              title
              categories {
                _key
                showcaseCategory {
                  title
                  slug {
                    current
                  }
                }
              }
              slug {
                current
              }
              thumbnail {
                ...SanityImage
                alt
              }
            }
          }
          button {
            blank
            secondaryButton
            text
            url
            document {
              asset {
                originalFilename
                url
              }
            }
          }
        }
        ... on SanityFcMultiColumnContent {
          _key
          _type
          title
          content {
            _key
            icon {
              asset {
                _id
              }
            }
            title
            _rawContent
            link {
              blank
              text
              url
            }
          }
          themeColorBg
          centerAligned
          alternativeLayout
        }
        ... on SanityProductPageHeader {
          _type
          title
          color
          bg {
            ...Si
          }
        }
        ... on SanityFcImage {
          _key
          _type
          fullwidth
          image {
            ...SanityImage
            alt
            caption
          }
        }
        ... on SanityProductTechnology {
          _type
        title
        subtitle
        _rawDescription
        partners {
          _key
          partner {
            name
            logo {
              asset {
                extension
                url
                _id
              }
              alt
            }
            slug {
              current
            }
            link
          }
        }
        featuredBlocks {
          _key
          title
          _rawContent
          link {
            blank
            text
            url
          }
          image {
            ...SanityImage
            alt
          }
          switchOrder
        }
        multiColumnContent {
          title
          content {
            _key
            icon {
              asset {
                _id
              }
            }
            title
            _rawContent
            link {
              blank
              text
              url
            }
          }
          themeColorBg
          centerAligned
          alternativeLayout
        }
      }
        ... on SanityProductFeaturesNew {
          _type
          title
          caption
          position
          verticalTabs
          features {
            featureImage {
              ...Si
            }
            title
            featureItems {
              title
              description
              caption
              position
              icon {
                ...Si
              }
              cta {
                title
                type
                slug {
                  __typename
                  ... on SanityFeaturePage {
                    slug {
                      current
                    }
                    correspodingProduct {
                      slug {
                        current
                      }
                    }
                  }
                  ... on SanityProductPage {
                    slug {
                      current
                    }
                  }
                }
                link
              }
            }
          }
        }
        ... on SanityDeploymentOptions {
          _type
          title
          deploymentHeader {
            title
            image {
              ...Si
            }
          }
          options {
            header
            dedicated
            onDemand
            hybrid
          }
        }
        ... on SanityCaptionTitleText {
          _type
          title
          position
          _rawDescription
          caption
        }
        ... on SanityProductCallToAction {
          _type
          title
          _rawDescription
          button {
            blank
            secondaryButton
            text
            url
          }
          bg {
            ...Si
          }
        }
        ... on SanityFeaturedBlock {
          _key
          _type
          caption
          position
          featureStyling
          title
          _rawContent
          link {
            blank
            text
            url
          }
          image {
            ...SanityImage
            alt
          }
          switchOrder
        }
        ... on SanityProductHowItWorks {
          _type
          title
          subtitle
          _rawContent
          image {
            ...SanityImage
            alt
          }
        }
      }
      seo {
        title
        description
        thumbnail {
          asset {
            _id
          }
          alt
        }
      }
    }
  }
`;

const PageTemplate = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const page = (data || {})?.productPage;

  const title = page.seo?.title || page.title;
  const description = page.seo?.description;
  const image = page.seo?.thumbnail || page.thumbnail;
  const autoSiteTitle = !page.seo?.title;
  const pageHeaderBlock = page.flexibleContent.find(
    (block) => block._type === "productPageHeader"
  );

  const updatedFlexibleContent = page.flexibleContent.filter(
    (block) => block._type !== "productPageHeader"
  );

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        image={image}
        autoSiteTitle={autoSiteTitle}
      />
      {pageHeaderBlock.title && (
        <PageHeader
          title={pageHeaderBlock.title}
          subtitle={pageHeaderBlock.subtitle}
          bg={pageHeaderBlock.bg}
          color={pageHeaderBlock.color}
        />
      )}
      {page.flexibleContent && <ProductMain content={updatedFlexibleContent} />}
    </Layout>
  );
};

export default PageTemplate;
